<template>
  <moe-page title="APP广告修改">
    <moe-form ref="paramsForm" :model="params" :rules="rules" :showBack="false" :showClose="true">

      <el-form-item label="广告类型" prop="type">
        <moe-select v-model="params.type" type="appAdvertType" placeholder="请选择广告类型" />
      </el-form-item>

      <el-form-item label="媒体类型" prop="urlType">
        <el-radio-group v-model="params.urlType" @change="handleChange">
          <el-radio label="1">图片</el-radio>
          <el-radio label="2">视频</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="广告图片" prop="coverUrl" v-if="params.urlType === '1'">
        <moe-upload-file
          v-model="params.coverUrl"
          ossKey="AD_MEDIA"
          :default-file-list="coverList"
          type="image"
          upload-text="上传图片"
          :getFileRatio="true"
          :ratio.sync="params.ratio"
          @change="$refs.paramsForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="广告视频" prop="coverUrl" v-else-if="params.urlType === '2'">
        <moe-upload-file
          v-model="params.coverUrl"
          ossKey="AD_MEDIA"
          :default-file-list="coverList"
          type="video"
          upload-text="上传视频"
          :getFileRatio="true"
          :ratio.sync="params.ratio"
          :size="100"
          @change="() => $refs.paramsForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="跳转类型" prop="form">
        <moe-select v-model="params.form" type="appAdvertForm" placeholder="请选择跳转类型" @change="changeSelect('form')"></moe-select>
      </el-form-item>

      <el-form-item label="外部跳转链接" prop="link" v-if="params.form === 1">
        <el-input v-model="params.link" placeholder="请输入外部跳转链接"></el-input>
      </el-form-item>

      <el-form-item label="内部跳转地址" prop="link" v-if="params.form === 2">
        <moe-select type="advertLink" v-model="params.link" placeholder="请选择内部跳转地址" @change="changeSelect('link')"></moe-select>
      </el-form-item>

      <!-- N元任选专区 -->
      <el-form-item label="活动编号" prop="activityId" v-if="params.link === '/pages/activity/optional/detail'">
        <el-input v-model="params.activityId" placeholder="请输入活动编号"></el-input>
      </el-form-item>

      <el-form-item label="商品编号" prop="link" v-if="params.form === 3">
        <el-input type="number" v-model.trim="params.link" placeholder="请输入商品编号" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="商品专区编号" prop="link" v-if="params.form === 4">
        <el-input type="number" v-model.trim="params.link" placeholder="请输入商品专区编号" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input :value="params.sort" @input="(value) => params.sort = value.replace(/^\.+|[^\d]/g,'')" placeholder="请输入广告排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="展示时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([params.startTime, params.endTime] = datetime || ['', ''])" />
          <div class="color-danger">（广告展示时间，时间结束广告自动关闭，如未选时间则一直存在，需手动关闭）</div>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" autosize v-model="params.remark" placeholder="请输入备注"></el-input>
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="paramsLoad" @click="submit">
          {{ paramsLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: '',
  data() {
    let params = {
      type: '',         //位置：1.app首页轮播图 2.app打开加载广告
      urlType: '1',     //媒体类型
      coverUrl: '',     //图片地址
      ratio: [],        //图片比率
      form: 1,          //跳转类型 1：外部 2：内部
      link: '',         //链接地址或者内部（待接入）
      activityId: '',   //本地储存字段,不提交后台
      sort: '',         //排序
      remark: '',       //备注
      startTime: '',
      endTime: '',
    }
    //数据校验
    const verify = this.$moe_verify.verifyForm;
    const checkLink = (rule, value, callback) => {
      if (!value) {
        let { form } = this.params;
        if (form === 1) {
          return callback(new Error(`请输入外部跳转链接`));
        } else if (form === 2) {
          return callback(new Error(`请输入内部跳转链接`));
        } else if (form === 3) {
          return callback(new Error(`请输入商品编号`));
        } else if (form === 4) {
          return callback(new Error(`请输入商品专区编号`));
        }
      } else {
        callback();
      }
    }
    return {
      params,
      datetime: [],
      coverList: [],
      rules: {
        type: verify.isEmpty('请选择广告类型'),
        urlType: verify.isEmpty('请选择媒体类型'),
        coverUrl: verify.isEmpty('请上传广告图片'),
        form: verify.isEmpty('请选择跳转类型'),
        link: [{ required: true, validator: checkLink, trigger: ['blur', 'change'] }],
        activityId: verify.isEmpty('请输入活动编号'),
      },
      paramsLoad: false,
    }
  },
  methods: {
    changeSelect(source) {
      switch (source) {
        case 'form':
          this.params.link = '';
          this.params.activityId = '';
          break;
        case 'link':
          this.params.activityId = '';
          break;
      }
    },
    handleChange() {
      this.params.coverUrl = '';
      this.params.ratio = [];
      this.coverList.splice(0);
    },
    submit() {
      this.$refs['paramsForm'].validate(() => {
        this.paramsLoad = true;

        let params = this.$moe_lodash.cloneDeepData({
          ...this.params,
          ratio: JSON.stringify(this.params.ratio),
        })
        if (params.link === '/pages/activity/optional/detail') {
          params.link = `${params.link}?id=${params.activityId}`;
          delete params.activityId;
        }
        this.$moe_api.ADVERT_API.appAdvertUpdate(params).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('修改成功', {
              completionHandler: () => {
                this.$moe_coordinator.navigateBack();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.paramsLoad = false;
        })
      })
    },
  },
  mounted() {
    this.$moe_api.ADVERT_API.appAdvertDetails({ id: this.$route.query.id }).then((data) => {
      if (data.code === 200) {
        let { id, type, urlType, coverUrl, ratio, form, link, sort, remark, startTime, endTime } = data.result;
        let newLink = '', activityId = '';
        if (form === 2 && link.indexOf('?') > -1 && ['/pages/activity/optional/detail'].includes(link.split('?')[0])) { // N元任选活动
          newLink = link.split('?')[0]
          activityId = this.$moe_formatter.getUrlParams(link).id;
        } else {
          newLink = link
        }
        this.params = {
          id,
          type,
          urlType,
          coverUrl: this.$moe_yunHelper.formatterImageUrl(coverUrl),
          ratio: ratio ? JSON.parse(ratio) : [],
          form,
          link: newLink,
          activityId,
          sort,
          remark,
          startTime,
          endTime,
        }
        if (startTime) {
          this.datetime = [startTime, endTime];
        }
        this.coverList = [{
          url: coverUrl,
          tempFilePath: this.$moe_yunHelper.getTempFilePath(coverUrl),
        }]
      } else {
        this.$moe_msg.error(data.message);
      }
    })
  }
}
</script>